<template>
    <div class="html-content" :class="{ 'html-content--small': isSmall }">
        <div class="sun-editor-editable" v-html="html"></div>

        <EmbeddedVideo v-if="ytbMatcher" ref="youtube" :video-id="ytbMatcher[1]" class="mx-auto" />
    </div>
</template>

<script>
import EmbeddedVideo from '@/components/EmbeddedVideo';

export default {
    name: 'HTMLContent',
    components: {
        EmbeddedVideo
    },
    props: {
        content: {
            type: String,
            required: true
        },
        isSmall: Boolean
    },
    computed: {
        ytbMatcher() {
            return this.content.match(/\[youtube:(.*)\]/);
        },
        html() {
            return this.content.replace(/\[youtube:(.*)\]/, "<span id='" + this.placeholderId + "'></span>");
        },
        placeholderId() {
            const hash = Math.random().toString(36).substr(2, 10);
            return `placeholder-${hash}`;
        }
    },
    methods: {
        insertYoutube() {
            const youtube = this.$refs.youtube;
            if (youtube) {
                const target = document.getElementById(this.placeholderId);
                target.appendChild(youtube.$el);
            }
        }
    },
    watch: {
        html() {
            this.$nextTick(() => {
                this.insertYoutube();
            });
        }
    },
    mounted() {
        this.insertYoutube();
    }
};
</script>

<style lang="scss">
@use 'sass:meta';
@use '@/styles/variables';

.html-content {
    @include meta.load-css(
        '@/styles/page-html-content.scss'
    ); //cannot use themification inside, will not get applied via meta.load-css

    &--small .sun-editor-editable {
        font-size: 80%;
        color: variables.$muted;

        ::v-deep {
            h1,
            .h1 {
                font-size: 20px;
            }

            h2,
            .h2 {
                font-size: 18px;
            }

            h3,
            .h3 {
                font-size: 16px;
            }

            h4,
            .h4 {
                font-size: 14px;
            }
        }
    }
}
</style>

<template>
    <div class="gdpr d-print-none" @click="$emit('update:accepted', true)">
        <span class="text">
            <slot>
                <translate path="GDPR.overlayText" :params="{ appliedTo }">
                    <nuxt-link
                        slot="link"
                        :to="{ name: 'category', params: { category: 'datenschutz' } }"
                        target="_blank"
                        @click.native="$event.stopImmediatePropagation()"
                        class="gdpr-link"
                    >
                        {{ $t('DataProtection.link').trim() }}
                    </nuxt-link>
                </translate>
            </slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'GDPROverlay',
    props: {
        accepted: Boolean,
        appliedTo: String
    },
    computed: {
        theme() {
            return this.$store.state.theme;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

.gdpr {
    width: 100%;
    height: 100%;
    background: variables.$gdpr-overlay;
    color: hsl(0, 0%, 100%);
    text-align: center;
    transition: opacity 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;

    &:hover {
        opacity: 1;
    }

    .text {
        font-size: 24px;
        padding: 0 20px;

        a {
            color: hsl(0, 0%, 100%) !important;
            text-decoration: underline !important;
            display: inline-block;
        }
    }

    @media (screen and pointer: coarse) {
        opacity: 1;
        background-color: variables.$gdpr-overlay;
    }

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        .text {
            font-size: 15px;
            padding: 0 10px;
        }
    }
}
</style>

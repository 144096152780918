<template>
    <div class="video embed-responsive embed-responsive-16by9" :style="previewStyles">
        <GDPROverlay
            v-if="!agreedToGDPR"
            :accepted.sync="agreedToGDPR"
            appliedTo="Youtube"
            class="embed-responsive-item"
        />
        <iframe
            v-else
            :src="youtubeUrl"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="embed-responsive-item video__iframe"
        >
        </iframe>
    </div>
</template>

<script>
import GDPROverlay from '@/components/GDPROverlay';

export default {
    name: 'EmbeddedVideo',
    components: {
        GDPROverlay
    },
    data() {
        return {
            videoHost: this.$config.videoHost
        };
    },
    props: {
        videoId: String
    },
    computed: {
        previewStyles() {
            return `background-image:url(https://${this.videoHost}/vi/${this.videoId}/0.jpg)`;
        },
        youtubeUrl() {
            return `https://www.youtube-nocookie.com/embed/${this.videoId}?rel=0&amp;showinfo=0`;
        },
        agreedToGDPR: {
            get() {
                return this.$store.state.agreedToGDPR;
            },
            set() {
                this.$store.dispatch('agreeToGDPR');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.video {
    max-width: 560px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &__iframe {
        position: absolute !important; //sun-editor overrides this
    }
}
</style>

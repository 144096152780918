<template>
    <div id="uspBanner" class="container-fluid">
        <div class="row banner-row">
            <Link
                mask
                :to="{ name: 'category', params: { category: pageUrl }, hash: '#1' }"
                class="banner-item"
                :title="$t('Banner.christmas.includingProtection.title')"
            >
                <IconSvg filepath="banner/shield-outline.svg" />
                <span>{{ $t('Banner.christmas.includingProtection') }}</span>
            </Link>
            <Link
                mask
                :to="{ name: 'category', params: { category: pageUrl }, hash: '#2' }"
                class="banner-item"
                :title="$t('Banner.christmas.yearsValid.title')"
            >
                <IconSvg filepath="banner/return-policy.svg" />
                <translate path="Banner.christmas.yearsValid">
                    <template v-slot:amount>3</template>
                    <template v-slot:year>{{ $t('Generic.year', null, 3) }}</template>
                </translate>
            </Link>
            <Link
                mask
                :to="{ name: 'category', params: { category: pageUrl }, hash: '#3' }"
                class="banner-item"
                :title="$t('Banner.christmas.daysReturnRight.title')"
            >
                <IconSvg filepath="banner/return-time-limit-30.svg" class="calendar-icon" />
                <translate path="Banner.christmas.daysReturnRight">
                    <template v-slot:amount>30</template>
                    <template v-slot:day>{{ $t('Generic.day', null, 30) }}</template>
                </translate>
            </Link>
            <Link
                mask
                :to="{ name: 'category', params: { category: pageUrl }, hash: '#4' }"
                class="banner-item"
                :title="$t('Banner.christmas.perfectGift.title')"
            >
                <IconSvg filepath="banner/heart.svg" class="heart-icon" />
                <span>{{ $t('Banner.christmas.perfectGift') }}</span>
            </Link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'USPBanner',
    computed: {
        pageUrl: function () {
            return this.$t('USPBanner.pageUrl') || 'ihre-vorteile';
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@use '@/styles/bootstrap/breakpoints';
@use '@/styles/variables';

#uspBanner {
    background: var(--uspBanner-bg);
    box-shadow: var(--uspBanner-shadow);
    color: var(--uspBanner-color);
    line-height: 30px;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 50px;
    font-weight: 700;
    z-index: 7;
    position: relative;

    .banner-row {
        flex-wrap: nowrap !important;
        justify-content: space-evenly;
    }

    .banner-item {
        text-align: center;
        white-space: nowrap;
        color: var(--uspBanner-color);

        &[title] {
            cursor: help;
        }
    }

    .icon-svg {
        margin-right: 8px;
    }

    .calendar-icon {
        margin-top: -8px;
    }

    .heart-icon {
        margin-top: -3px;
    }

    @include breakpoints.media-breakpoint-down(md) {
        $animation-duration: 16s;
        $slides: 4;
        //$keyframe-percentage: ($amount-of-slides * 100) / $animation-duration * 1%;

        .banner-item {
            padding: 0 15px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            line-height: 50px;

            @for $i from 1 through $slides {
                &:nth-child(#{$i}) {
                    animation: fade#{$i} $animation-duration infinite cubic-bezier(0.65, 0.01, 0.32, 1);
                }
                @keyframes fade#{$i} {
                    @for $j from 0 through $slides {
                        #{percentage($j / $slides)} {
                            opacity: if($i==$j + 1 or ($i==1 and $j==$slides), 1, 0);
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
@use '@/styles/bootstrap/breakpoints';

@include breakpoints.media-breakpoint-down(md) {
    .header-mobile--transparent + #main #uspBanner {
        padding-top: 40px;
    }
}
</style>

import USPBanner from '@/components/banners/USPBanner';

/**
 * @mixin
 * @param pageKey {String}
 */
export default (pageKey) => {
    console.assert(pageKey, 'Page key must be specified');

    return {
        components: {
            USPBanner
        },
        computed: {
            pageStyle() {
                return this.$getSafe(this, `${pageKey}.pageStyle`);
            },
            uspBannerVisible() {
                return this[pageKey].uspBannerVisible;
            },
            giftBoxBannerVisible() {
                return this[pageKey].giftBoxBannerVisible;
            }
        },
        beforeMount() {
            if (this.pageStyle) {
                document.body.classList.add(`pageStyle_${this.pageStyle}`);
            }
        },
        beforeDestroy() {
            if (this.pageStyle && process.client) {
                document.body.classList.remove(`pageStyle_${this.pageStyle}`);
            }
        }
    };
};
